/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DarkLink from './DarkLink';
import StandardLinkPropTypes from '../StandardLinkPropTypes';

function LightLink(props) {
  return <DarkLink {...props} light />;
}

LightLink.propTypes = StandardLinkPropTypes;

LightLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default LightLink;
