import React from 'react';
import { LinkIconPropTypes } from './StandardLinkPropTypes';
import Arrow from '../../assets/icons/Arrow';
import ArrowSquare from '../../assets/icons/ArrowSquare';
import LocationPin from '../../assets/icons/LocationPin';
import Download from '../../assets/icons/Download';
import Search from '../../assets/icons/Search';
import Profile from '../../assets/icons/Profile';
import styles from './ElementLink.module.scss';

function LinkIcon({ icon, size, className, circle, testid }) {
  return (
    <span role="none" data-testid={`${testid}-icon`} className={className}>
      {circle && icon === 'arrow' && <Arrow className={styles[`icon-size-${size}`]} />}
      {!circle && icon === 'arrow' && <ArrowSquare />}
      {icon === 'location' && <LocationPin />}
      {icon === 'download' && <Download />}
      {icon === 'magnifying glass' && <Search />}
      {icon === 'profile' && <Profile />}
    </span>
  );
}

LinkIcon.propTypes = LinkIconPropTypes;
LinkIcon.defaultProps = {
  icon: null
};

export default LinkIcon;
