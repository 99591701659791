import React from 'react';
import PropTypes from 'prop-types';

export const DownloadPropTypes = {
  className: PropTypes.string
};

function Download({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      imageRendering="auto"
      baseProfile="basic"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 212 198"
    >
      <title>Download Icon</title>
      <g transform="translate(50 0)">
        <path
          stroke="none"
          d="M110.4 41.5L99.4 31 62 66.5 62 0 47.5 0 47.5 67 10.5 31 0 41.5 55 94 110.4 41.5Z"
        />
      </g>
      <g transform="translate(0 138.5)">
        <path
          fill="#000"
          stroke="none"
          d="M197.4 0L197.4 45 14.5 45 14.5 .5 0 .5 0 59.5 212.4 59.5 212.4 0 197.4 0Z"
        />
      </g>
    </svg>
  );
}

Download.propTypes = DownloadPropTypes;

Download.defaultProps = {
  className: null
};

export default Download;
