/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import ErrorBoundary from '../ErrorBoundary';
// import getImgSrcTag from '../../utils/getImgSrcTag';
import getOptimizedImageURL from '../../utils/getOptimizedImageURL';

export const ContentfulImagePropTypes = {
  image: PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  priority: PropTypes.bool,
  columns: PropTypes.number,
  testId: PropTypes.string,
  itemProp: PropTypes.string
};

export const ImagePropTypes = PropTypes.oneOfType([
  PropTypes.shape(ContentfulImagePropTypes)
]).isRequired;

const contentfulLoader = ({ src: url, width, quality: q }) => {
  return getOptimizedImageURL({ url, width, q });
};

const Image = React.forwardRef(function Image(
  {
    src,
    image,
    testId,
    columns = 12,
    className,
    itemProp,
    maxwidth,
    displaymedia,
    priority,
    ...imageProps
  },
  ref
) {
  let sizes = '100vw';
  if (columns < 12) {
    // Assumes 6 columns on 640px mobile
    sizes = `(max-width: 640px) ${(columns * 100) / 6}vw, ${columns * (100 / 12)}vw`;
  }
  try {
    let imgUrl =
      src ??
      image?.url ??
      'https://images.ctfassets.net/hhv516v5f7sj/2Frv2X3GrlM5Tsmm1HZwFJ/3725f6c80f0ac3b3fff43d9f75d0955c/Burger_Cooking6.jpg';
    if (imgUrl?.startsWith('//')) imgUrl = `https:${imgUrl}`;
    if (!imgUrl) {
      return null;
    }
    const isSVG = imgUrl?.includes('.svg');

    return (
      <ErrorBoundary>
        <NextImage
          {...imageProps}
          src={imgUrl}
          ref={ref}
          // Don't add an empty alt attribute if there's no image description
          {...(image?.description ? { alt: image.description } : null)}
          data-testid={testId}
          className={className}
          width={image?.width || 400}
          height={image?.height || 800}
          itemProp={itemProp}
          priority={priority}
          sizes={sizes}
          quality={90}
          unoptimized={isSVG}
          loader={contentfulLoader}
        />
      </ErrorBoundary>
    );
  } catch (err) {
    return null;
  }
});

Image.propTypes = ImagePropTypes;
Image.defaultProps = {
  testId: 'Image',
  priority: false
};

export default React.memo(Image);
