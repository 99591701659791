import React from 'react';
import cx from 'classnames';
import styles from './ImageLink.module.scss';
import StandardLinkPropTypes from '../StandardLinkPropTypes';
import Image from '../../Image';

export function getButtonContent(props, variant) {
  const {
    href,
    linkText,
    icon,
    imageSrc,
    target,
    isModal,
    download,
    trackingId,
    onClick,
    isNextLink,
    style,
    ...linkProps
  } = props;

  const linkContent = (
    <Image
      image={{ description: linkText || 'Link', url: imageSrc }}
      columns={4}
      className={styles.img}
    />
  );

  if (isNextLink)
    return (
      <span
        role="none"
        data-testid={variant}
        href={href}
        icon={icon}
        target={target}
        isModal={isModal}
        download={download}
        data-trackingid={trackingId}
        onClick={onClick}
        {...linkProps}
        className={cx('btn', styles.button, linkProps.className)}
      >
        {linkContent}
      </span>
    );
  return (
    <a
      data-testid={variant}
      href={href}
      icon={icon}
      target={target}
      isModal={isModal}
      download={download}
      rel="noopener noreferrer"
      data-trackingid={trackingId}
      onClick={onClick}
      {...linkProps}
      className={cx('btn', styles.button, linkProps.className)}
    >
      {linkContent}
    </a>
  );
}

function ImageLink(props) {
  return getButtonContent(props, 'ImageLink');
}

ImageLink.propTypes = StandardLinkPropTypes;

ImageLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default ImageLink;
