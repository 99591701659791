import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import stylesLight from './LightButton.module.scss';
import stylesDark from '../DarkButton/DarkButton.module.scss';
import stylesBlue from '../BlueButton/BlueButton.module.scss';
import StandardLinkPropTypes from '../StandardLinkPropTypes';

export function getButtonContent(props, variant) {
  const {
    _id,
    _contentTypeId,
    internalTitle,
    href,
    linkText,
    icon,
    target,
    isModal,
    download,
    trackingId,
    onClick,
    isNextLink,
    style,
    anchorTagName,
    hasMenu,
    imageSrc,
    manualUrl,
    destinationType,
    ...linkProps
  } = props;

  let styles;
  if (variant === 'DarkButton') styles = stylesDark;
  if (variant === 'LightButton') styles = stylesLight;
  if (variant === 'BlueButton') styles = stylesBlue;

  const linkContent = (
    <span data-testid={`${variant}-linkText`} className={styles.text}>
      {linkText}
    </span>
  );

  if (isNextLink)
    return (
      <span
        role="none"
        data-testid={variant}
        href={href}
        icon={icon}
        target={target}
        isModal={isModal}
        download={download}
        data-trackingid={trackingId}
        onClick={onClick}
        {...linkProps}
        className={cx('btn', styles[kebabCase(style)], linkProps.className)}
      >
        {linkContent}
      </span>
    );
  return (
    <a
      data-testid={variant}
      href={href}
      icon={icon}
      target={target}
      isModal={isModal}
      download={download}
      rel="noopener noreferrer"
      data-trackingid={trackingId}
      onClick={onClick}
      {...linkProps}
      className={cx('btn', styles[kebabCase(style)], linkProps.className)}
    >
      {linkContent}
    </a>
  );
}

function LightButton(props) {
  return getButtonContent(props, 'LightButton');
}

LightButton.propTypes = StandardLinkPropTypes;

LightButton.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default LightButton;
