import PropTypes from 'prop-types';

const icons = ['arrow', 'magnifying glass', 'location', 'profile', 'cart', 'download'];
const iconSizes = ['small', 'medium', 'large'];

const StandardLinkPropTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(icons),
  iconSize: PropTypes.oneOf(iconSizes),
  target: PropTypes.string,
  isModal: PropTypes.bool.isRequired,
  download: PropTypes.bool.isRequired,
  trackingId: PropTypes.string,
  isNextLink: PropTypes.bool,
  onClick: PropTypes.func
};

export const LinkIconPropTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(icons),
  iconSize: PropTypes.oneOf(iconSizes),
  target: PropTypes.string,
  isModal: PropTypes.bool,
  download: PropTypes.bool,
  trackingId: PropTypes.string,
  isNextLink: PropTypes.bool,
  onClick: PropTypes.func
};

export default {
  ...StandardLinkPropTypes
};
