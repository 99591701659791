import React from 'react';
import PropTypes from 'prop-types';

function SearchIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      focusable="false"
    >
      <path
        fill="#3140EB"
        fillRule="evenodd"
        d="M8.24 1.67c2.808 0 5.092 2.27 5.095 5.071.002 2.806-2.267 5.045-4.998 5.092-2.862.049-5.186-2.285-5.18-5.08A5.09 5.09 0 0 1 8.24 1.67m-5.283 9.276c-.056.037-.114.065-.158.108L.305 13.529c-.223.22-.346.472-.293.794.1.595.848.885 1.32.508.064-.052.125-.109.183-.167.818-.824 1.635-1.649 2.45-2.474.04-.04.075-.086.113-.131 1.763 1.307 3.7 1.74 5.817 1.229 1.574-.38 2.849-1.248 3.804-2.554 1.997-2.728 1.649-6.514-.727-8.799-2.502-2.407-6.22-2.584-8.955-.443a6.466 6.466 0 0 0-1.68 1.996 6.728 6.728 0 0 0-.585 5.096c.24.852.642 1.62 1.205 2.363"
      />
    </svg>
  );
}

SearchIcon.propTypes = {
  className: PropTypes.string
};

SearchIcon.defaultProps = {
  className: ''
};

export default SearchIcon;
