import BaseLink from './BaseLink';
import DarkLink, { LightLink, BlueLink } from './DarkLink';
import DarkButton from './DarkButton';
import LightButton from './LightButton';
import BlueButton from './BlueButton';
import ImageLink from './ImageLink';

export default {
  'light base': BaseLink,
  'dark base': BaseLink,
  'dark link': DarkLink,
  'light link': LightLink,
  'dark button': DarkButton,
  'dark button transparent': DarkButton,
  'light button': LightButton,
  'light button transparent': LightButton,
  'blue button': BlueButton,
  'blue button transparent': BlueButton,
  'blue link': BlueLink,
  'rounded button': BaseLink,
  'image link': ImageLink
};
