/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import styles from './BaseLink.module.scss';
import StandardLinkPropTypes from '../StandardLinkPropTypes';
import LinkIcon from '../LinkIcon';

function BaseLink({
  icon,
  style,
  href,
  linkText,
  target,
  trackingId,
  isNextLink,
  onClick,
  hasMenu,
  imageSrc,
  ...linkProps
}) {
  const isInternal = href?.startsWith('/');
  const linkContent = (
    <>
      <span data-testid="BaseLink-linkText" className={cx(styles.ctaText)}>
        {linkText}
      </span>
      {icon && (
        <LinkIcon
          icon={icon}
          href={href}
          testid="BaseLink"
          className={cx(
            styles[kebabCase(icon)],
            styles.icon,
            !isInternal && icon === 'arrow' && styles.arrowSquare
          )}
        />
      )}
    </>
  );

  if (isNextLink)
    return (
      <span
        data-testid="BaseLink"
        className={cx(styles.baseLink, icon && styles.hasIcon, styles[kebabCase(style)])}
        data-trackingid={trackingId}
        {...linkProps}
      >
        {linkContent}
      </span>
    );

  return (
    <a
      data-testid="BaseLink"
      className={cx(styles.baseLink, icon && styles.hasIcon, styles[kebabCase(style)])}
      href={href}
      target={target}
      rel="noopener noreferrer"
      data-trackingid={trackingId}
      onClick={onClick}
      {...linkProps}
    >
      {linkContent}
      {!isNextLink && target === '_blank' && (
        <span className="sr-only sr-only-focusable">(opens in a new tab)</span>
      )}
    </a>
  );
}

BaseLink.propTypes = StandardLinkPropTypes;
BaseLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default BaseLink;
