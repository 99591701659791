import React from 'react';
import PropTypes from 'prop-types';

export const LocationPinPropTypes = {
  className: PropTypes.string
};

function LocationPin({ className }) {
  return (
    <svg
      aria-hidden="true"
      className={className}
      focusable="false"
      imageRendering="auto"
      baseProfile="basic"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 87 111"
    >
      <title>Location Pin Icon</title>
      <path d="M43.5,13C60.3,13.1,74,26.7,74,43.5C74,61,55.6,82.7,43.5,94.5c-4.4-4.3-8.6-8.8-12.5-13.6C19.2,66.6,13,53.7,13,43.5 C13,26.7,26.7,13.1,43.5,13 M43.5,3C21.1,3,3,21.2,3,43.5C3,74,43.5,108,43.5,108S84,74,84,43.5C84,21.2,65.9,3,43.5,3z" />
      <circle cx="43" cy="44" r="10" />
    </svg>
  );
}

LocationPin.propTypes = LocationPinPropTypes;

LocationPin.defaultProps = {
  className: null
};

export default LocationPin;
