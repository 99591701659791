import React from 'react';
import PropTypes from 'prop-types';

export const ArrowPropTypes = {
  className: PropTypes.string
};

function Arrow({ className }) {
  return (
    <svg
      aria-hidden="true"
      className={className}
      focusable="false"
      imageRendering="auto"
      baseProfile="basic"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 62 35"
    >
      <title>Arrow Icon</title>
      <rect x="0" y="14.7" width="55.2" height="4.55" />
      <g transform="translate(40.9 0)">
        <path stroke="none" d="M.35 3L14.3 17.2 0 31.25 3.35 34.25 20.85 17.15 3.5 0 .35 3Z" />
      </g>
    </svg>
  );
}

Arrow.propTypes = ArrowPropTypes;
Arrow.defaultProps = {
  className: null
};

export default Arrow;
