import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import downloadMedia from 'downloadjs';
import mappings from './utils';
import ErrorBoundary from '../ErrorBoundary';
import getPathWithLocale from '../../utils/getPathWithLocale';
import { useAppProvider } from '../../../AppProvider/AppProvider.tsx';

const YoutubeOverlay = dynamic(() => import('../../generics/YoutubeOverlay'));
const NextLink = dynamic(() => import('./NextLink'));
const BaseLink = dynamic(() => import('./BaseLink'));
const WrappedLink = dynamic(() => import('./WrappedLink'));

const icons = [
  'arrow',
  'arrow-circle',
  'magnifying glass',
  'location',
  'profile',
  'cart',
  'download'
];
const styles = [
  'base',
  'dark base',
  'dark link',
  'dark button',
  'dark button transparent',
  'light base',
  'light link',
  'light button',
  'light button transparent',
  'blue link',
  'blue button',
  'blue button transparent',
  'rounded button'
];
const iconSizes = ['small', 'medium', 'large'];

export const ElementLinkPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  as: PropTypes.string,
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(icons),
  iconString: PropTypes.oneOf(icons),
  iconSize: PropTypes.oneOf(iconSizes),
  imageSrc: PropTypes.string,
  target: PropTypes.string,
  isModal: PropTypes.bool,
  download: PropTypes.bool,
  trackingId: PropTypes.string,
  style: PropTypes.oneOf(styles).isRequired,
  children: PropTypes.node,
  hasMenu: PropTypes.bool,
  label: PropTypes.string,
  noAriaLabel: PropTypes.bool
};

function ElementLink({
  _id,
  _contentTypeId,
  internalTitle,
  className,
  as,
  href,
  linkText,
  icon,
  iconString,
  iconSize,
  imageSrc,
  target,
  isModal,
  download,
  trackingId,
  style,
  children,
  hasMenu,
  label,
  noAriaLabel,
  ...linkProps
}) {
  // eslint-disable-next-line no-param-reassign
  icon = icon || iconString;
  const router = useRouter();
  const [showVideo, setShowVideo] = React.useState(false);
  const { contentGroup } = useAppProvider();
  let StandardLink = children ? WrappedLink : mappings[style];
  if (!StandardLink) {
    StandardLink = BaseLink;
  }
  if (imageSrc) StandardLink = mappings['image link'];

  const trimmedHref = href?.trim();

  const isInternal = !!trimmedHref?.startsWith('/');
  const isTargetSelf = !target || target === '_self';
  const isYoutubeVideo = !!href?.includes('www.youtube.com/watch');
  const useNextLink = isInternal && !download && !isModal && isTargetSelf;

  const handleClick = (event) => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: 'cta_click',
        // TODO: How to determine the location?
        // cta_location: "body",
        link_text: linkText,
        link_url: `${window.location.origin}${href}`,
        content_group: contentGroup
      });
    }

    if (!isInternal && download) {
      event.preventDefault();
      downloadMedia(trimmedHref);
    }

    if (isModal && isYoutubeVideo) {
      event.preventDefault();
      setShowVideo(true);
    } else if (isModal) {
      event.preventDefault();
      const { pathname, asPath, query, replace } = router;
      const newPathname = queryString.stringifyUrl({
        url: pathname,
        query: { ...query, modal: 'ccpa' }
      });
      const newAsPath = queryString.stringifyUrl({ url: asPath, query: { modal: 'ccpa' } });
      replace(newPathname, newAsPath, { shallow: true });
    }
  };

  if (useNextLink) {
    return (
      <ErrorBoundary>
        <NextLink
          legacyBehavior
          aria-label={!noAriaLabel ? label || `go to ${trimmedHref}` : undefined}
          className={className}
          as={as}
          href={trimmedHref}
          trackingId={trackingId}
          onClick={handleClick}
          {...linkProps}>
          <StandardLink
            className={className}
            href={trimmedHref}
            linkText={linkText}
            icon={icon}
            iconSize={iconSize}
            imageSrc={imageSrc}
            style={style}
            target={target}
            isModal={isModal}
            download={download}
            trackingId={trackingId}
            hasMenu={hasMenu}
            isNextLink>
            {children}
          </StandardLink>
        </NextLink>
      </ErrorBoundary>
    );
  }

  let updatedHref = as || trimmedHref;
  if (updatedHref) {
    // generate locale url if it's not full URL, mailto, tel or anchor
    let skip = false;
    const skipList = ['http:', 'https:', 'mailto:', 'tel:', '//', '#'];
    skipList.forEach((x) => {
      if (updatedHref.startsWith(x)) {
        skip = true;
      }
    });

    if (!skip) {
      updatedHref = getPathWithLocale(
        updatedHref.startsWith('/') ? updatedHref : `/${updatedHref}`
      );
    }
  }

  return (
    <ErrorBoundary>
      <StandardLink
        className={className}
        as={as}
        href={updatedHref}
        style={style}
        linkText={linkText}
        icon={icon}
        iconSize={iconSize}
        imageSrc={imageSrc}
        target={target}
        isModal={isModal}
        download={download}
        trackingId={trackingId}
        hasMenu={hasMenu}
        onClick={handleClick}
        aria-label={!noAriaLabel ? label || `go to ${trimmedHref}` : undefined}
        {...linkProps}>
        {children}
        {!isInternal && <span className="sr-only sr-only-focusable">(opens in a new tab)</span>}
      </StandardLink>
      {isYoutubeVideo && (
        <YoutubeOverlay
          url={trimmedHref}
          open={showVideo}
          onRequestClose={() => setShowVideo(false)}
        />
      )}
    </ErrorBoundary>
  );
}

ElementLink.propTypes = ElementLinkPropTypes;

ElementLink.defaultProps = {
  className: null,
  as: null,
  target: null,
  trackingId: null,
  icon: null,
  iconSize: 'medium',
  children: null,
  imageSrc: null,
  hasMenu: null,
  label: null,
  iconString: null,
  isModal: false,
  download: false,
  noAriaLabel: false
};

export default ElementLink;
