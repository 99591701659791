const getOptimizedImageURL = ({ url, width, q, unoptimized }) => {
  if (url?.includes('.svg')) return url;
  let fetchUrl = `${url}`;
  if (unoptimized) return fetchUrl;
  // webp supports animated gifs, png and jpeg
  const options = ['fm=webp'];
  if (q) options.push(`q=${q}`);
  if (width && (fetchUrl?.includes('ctfassets') || fetchUrl?.includes('contentful'))) {
    if (width) options.push(`w=${Math.round(width)}`);
    fetchUrl = fetchUrl?.includes('?')
      ? `${fetchUrl}&${options.join('&')}`
      : `${fetchUrl}?${options.join('&')}`;
  } else {
    if (width) options.push(`width=${Math.round(width)}`);
    fetchUrl = fetchUrl?.includes('?')
      ? `${fetchUrl}&${options.join('&')}`
      : `${fetchUrl}?${options.join('&')}`;
  }
  return fetchUrl;
};

export default getOptimizedImageURL;
