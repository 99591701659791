import StandardLinkPropTypes from '../StandardLinkPropTypes';
import { getButtonContent } from '../LightButton';

function DarkButton(props) {
  return getButtonContent(props, 'DarkButton');
}

DarkButton.propTypes = StandardLinkPropTypes;

DarkButton.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default DarkButton;
