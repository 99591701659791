const defaultLocales = [
  'en-US',
  'zh-Hant-HK',
  'en-HK',
  'en-CA',
  'ca',
  'fr-CA',
  'fr-ca',
  'me-en',
  'en-AE',
  'nz-en',
  'en-NZ',
  'au-en',
  'en-AU',
  'gb-en',
  'en-GB',
  'hk-en',
  'hk',
  'zh-CN',
  'en-ZW',
  'cn',
  'cn-en'
];

const mappings = {
  'zh-Hant-HK': 'hk',
  'en-HK': 'hk-en',
  'en-CA': 'ca',
  'en-AE': 'me-en',
  'en-NZ': 'nz-en',
  'en-AU': 'au-en',
  'en-GB': 'gb-en',
  'zh-CN': 'cn',
  'en-ZW': 'cn-en'
};

const removeLocale = (path, locales = defaultLocales) => {
  const paths = path?.split('/');
  if (paths?.length > 1) {
    const locale = paths[1].split('?')[0];
    if (locales.includes(locale)) {
      paths.splice(1, 1);
      return paths.join('/');
    }
    return path;
  }
  return path;
};

const addLocale = (path, locale) => {
  if (locale === 'en-US') return path;
  return `/${mappings[locale] || locale}${path}`;
};

const getPath = ({ path, locale }) => {
  const nakedPath = removeLocale(path);
  const localizedPath = addLocale(nakedPath, locale);
  if (localizedPath === '') return '/';
  if (localizedPath === '/home') return '/';
  return localizedPath;
};

export const getLocale = (locale) => {
  if (locale === 'en-US') return 'en-US';
  return mappings[locale] || locale;
};

export default getPath;
