import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const SentryErrorBoundary = dynamic(() => import('@sentry/react').then((mod) => mod.ErrorBoundary));

export const ErrorBoundaryPropTypes = {
  children: PropTypes.node.isRequired
};

function FallbackComponent() {
  return <div>An error has occured</div>;
}

// TODO only show dialog on staging
function ErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary fallback={FallbackComponent} showDialog={false}>
      {children}
    </SentryErrorBoundary>
  );
}

ErrorBoundary.propTypes = ErrorBoundaryPropTypes;

export default ErrorBoundary;
