/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cx from 'classnames';
import kebabCase from 'lodash/kebabCase';
import darkStyles from './DarkLink.module.scss';
import lightStyles from './LightLink.module.scss';
import blueStyles from './BlueLink.module.scss';
import StandardLinkPropTypes from '../StandardLinkPropTypes';
import LinkIcon from '../LinkIcon';

/**
 * Retrieves the correct styles file given props
 */
function chooseStyles(light, blue) {
  let styles = light ? lightStyles : darkStyles;
  styles = blue ? blueStyles : styles;
  return styles;
}

function DarkLink({
  href,
  linkText,
  icon,
  iconString,
  iconSize,
  target,
  trackingId,
  isNextLink,
  light,
  blue,
  download,
  onClick,
  style,
  imageSrc,
  hasMenu,
  ...linkProps
}) {
  // eslint-disable-next-line no-param-reassign
  icon = icon || iconString;
  const styles = chooseStyles(light, blue);
  const isInternal = href && href.startsWith('/');
  const iconType = icon === 'arrow-circle' ? 'arrow' : icon;

  const circle = isInternal || icon === 'arrow-circle';

  const linkContent = (
    <>
      <span data-testid="DarkLink-linkText" className={cx(styles.text, styles[`size-${iconSize}`])}>
        {linkText}
      </span>
      {icon && (
        <LinkIcon
          icon={iconType}
          circle={circle}
          iconSize={iconSize}
          href={href}
          testid="DarkLink"
          size={iconSize}
          className={cx(
            styles[iconType],
            styles.icon,
            styles[`border-${iconSize}`],
            styles[kebabCase(iconType)],
            !isInternal && !circle && iconType === 'arrow' && styles.arrowSquare
          )}
        />
      )}
    </>
  );

  if (isNextLink)
    return (
      <span
        data-testid="DarkLink"
        data-trackingid={trackingId}
        {...linkProps}
        className={cx(
          'btn p-0 border-0',
          styles.cta,
          iconType && styles.hasIcon,
          linkProps.className
        )}
      >
        {linkContent}
      </span>
    );

  return (
    <a
      data-testid="DarkLink"
      href={href}
      icon={iconType}
      target={target}
      rel="noopener noreferrer"
      role="button"
      data-trackingid={trackingId}
      download={download}
      onClick={onClick}
      {...linkProps}
      className={cx(
        'btn p-0 border-0 dark-link',
        styles.cta,
        iconType && styles.hasIcon,
        linkProps.className
      )}
    >
      {linkContent}
    </a>
  );
}

DarkLink.propTypes = StandardLinkPropTypes;

DarkLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null,
  light: false,
  blue: false
};

export default DarkLink;
