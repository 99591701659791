/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DarkLink from './DarkLink';
import StandardLinkPropTypes from '../StandardLinkPropTypes';

function BlueLink(props) {
  return <DarkLink {...props} blue />;
}

BlueLink.propTypes = StandardLinkPropTypes;

BlueLink.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default BlueLink;
