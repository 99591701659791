import React from 'react';
import PropTypes from 'prop-types';

export const ProfileIconPropTypes = {
  className: PropTypes.string
};

function ProfileIcon({ className }) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      className={className}
      width="16px"
      height="19px"
      viewBox="0 0 16 19"
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
    >
      <title>Profile icon</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="icon_profile"
          transform="translate(-9.000000, -8.000000)"
          stroke="#4E4E4E"
          strokeWidth="1.5"
        >
          <g transform="translate(10.000000, 9.000000)">
            <circle id="Oval" cx="7" cy="4" r="4" />
            <path
              d="M14,17 C14,13.1340068 10.8659932,10 7,10 C3.13400675,10 0,13.1340068 0,17"
              id="Path"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

ProfileIcon.propTypes = ProfileIconPropTypes;

ProfileIcon.defaultProps = {
  className: null
};

export default ProfileIcon;
