import StandardLinkPropTypes from '../StandardLinkPropTypes';
import { getButtonContent } from '../LightButton';

function BlueButton(props) {
  return getButtonContent(props, 'BlueButton');
}

BlueButton.propTypes = StandardLinkPropTypes;

BlueButton.defaultProps = {
  className: null,
  icon: null,
  target: null,
  trackingId: null,
  onClick: null
};

export default BlueButton;
